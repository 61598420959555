import React from 'react';
import './App.css';
import videoBg from './BLR_Pilot_Kepler.mp4';

function App() {
  return (
    <div>
      {/* Header */}
      <header>
        <div className="container header-container">
          <h1 className="logo">Chitragupta</h1>
          <nav>
            <a href="#about">About</a>
            <a href="#features">Features</a>
            <a href="#contact">Contact</a>
            <a href="#signup" className="btn">Join Waitlist</a>
          </nav>
        </div>
      </header>

      {/* Hero Section */}
      <section className="hero" id="hero">
      <div className="video-background">
          <video autoPlay muted loop>
            <source src={videoBg} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        
        <div className="container">
          <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
          <h1>Launching Soon!</h1>
          <p>Chitragupta is a planetary scale sensory data company. <br></br> We observe the world through vision, audio & other sensors, and make it useful.</p>
          <a href="#signup" className="btn">Get Notified</a>
          
        </div>
      </section>

      {/* Sign-up Section */}
      <section className="signup" id="signup">
        <div className="container">
          <h2>Be the First to Know!</h2>
          <form action="#">
            <input type="email" placeholder="Enter your email" required />
            <button type="submit" className="btn">Sign Up</button>
          </form>
        </div>
      </section>

      {/* Footer */}
      <footer>
        <div className="container">
          <p>&copy; 2024 Chitragupta.co All rights reserved.</p>
          <div className="socials">
            <a href="#">Twitter</a>
            <a href="#">LinkedIn</a>
            <a href="#">Facebook</a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;